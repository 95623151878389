const consts = {
    // BackendUrl: "http://localhost:3000/api",
    //   BackendUrl: "http://localhost:3500/api/v1",
    //Main: "Development", 

    route: "",
    // MainUrl: "http://3.111.146.81",
    BackendUrl: "https://api.astrohub.ovh/api",

    //   Main: "Production",
    //  bookmaker: "DraftKings,",
    //  bookmakers: "DraftKings"
}

export default consts;